<template>
  <v-row class="options">
    <v-col
      v-for="(o, i) in optionsInternal"
      :key="o.text"
      class="col-12 col-sm-6 pa-1 pl-5 pr-5 mb-5"
      @click="onSelect(i)"
    >
      <div>
        <div v-if="isOptionsDisplaySchemeOddOneOut" :class="optionsClasses(i)">
          <div class="options__opt--answer flex-grow-1 justify-center pa-5">
            {{ o.text }}
          </div>
        </div>
        <div v-else :class="optionsClasses(i)">
          <v-icon x-large color="primary">{{ o.icon }}</v-icon>
          <div class="options__opt--answer flex-grow-1">{{ o.text }}</div>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  props: {
    options: {
      type: Array,
      required: true
    },
    correctAnswerIndex: { type: Number, required: true, default: 1 },
    showAnswer: { type: Boolean, default: false, required: true },
    optionsDisplayScheme: { type: String, default: '', required: false }
  },

  data() {
    return {
      selectedIndex: undefined,
      disableOptions: false
    }
  },
  computed: {
    isOptionsDisplaySchemeOddOneOut() {
      return this.optionsDisplayScheme === 'oddOneOut'
    },
    optionsInternal() {
      return this.options.map((o, i) => {
        let iconName = `mdi-alpha-${String.fromCharCode(
          'A'.charCodeAt(0) + i
        ).toLowerCase()}-circle`
        if (this.showAnswer) {
          if (this.selectedIndex === i) {
            if (this.correctAnswerIndex === i) {
              iconName = 'mdi-check-circle-outline'
            } else {
              iconName = 'mdi-alpha-x-circle-outline'
            }
          } else if (this.correctAnswerIndex === i) {
            iconName = 'mdi-check-circle-outline'
          }
        }
        return { text: o, icon: iconName }
      })
    },
    correctAnswerSelected() {
      return this.selectedIndex === this.correctAnswerIndex
    }
  },
  methods: {
    ...mapActions(['playSound']),
    onSelect(i) {
      if (!this.disableOptions) {
        this.selectedIndex = i
        this.$emit('onOptionSelect', i)
        this.playSound('click')
      }
    },
    optionsClasses(index) {
      const APPEND_DISPLAY_SCHEME_CLASS = (c) =>
        c + c + '__' + this.optionsDisplayScheme
      let classes =
        'd-flex text-subtitle-1 text-sm-h6 font-weight-normal' +
        APPEND_DISPLAY_SCHEME_CLASS(' options__opt')
      if (index === this.selectedIndex) {
        classes += APPEND_DISPLAY_SCHEME_CLASS(' options__opt--selected')
      } else {
        classes += APPEND_DISPLAY_SCHEME_CLASS(' options__opt--not-selected')
      }
      if (this.showAnswer && index === this.correctAnswerIndex) {
        classes += APPEND_DISPLAY_SCHEME_CLASS(' options__opt--correctAnswer')
      }
      if (
        this.showAnswer &&
        index === this.selectedIndex &&
        index !== this.correctAnswerIndex
      ) {
        classes += APPEND_DISPLAY_SCHEME_CLASS(' options__opt--wrongAnswer')
      }
      if (this.showAnswer || index === this.selectedIndex) {
        classes += ' options__opt--no-hover'
      }
      return classes
    }
  },
  watch: {
    showAnswer(newValue) {
      newValue && this.$emit('onShowAnswer', this.correctAnswerSelected)
      this.disableOptions = true
      if (newValue) {
        this.correctAnswerSelected
          ? this.playSound('correct')
          : this.playSound('incorrect')
      }
    },
    options(newValue) {
      this.selectedIndex = undefined
      this.disableOptions = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../styles/global.scss';

.options {
  margin-top: 10px;
  margin-bottom: 10px;
  &__opt {
    background: white;
    border-radius: 50px;
    padding: 5px;
    border: 1px solid var(--v-primary-base);
    align-items: center;
    cursor: pointer;

    &--char {
      background-color: #6e1313;
      border-radius: 50%;
      text-align: center;
      width: 40px;
      height: 40px;
      align-content: center;
      display: grid;
      &--selected {
        background-color: #074607;
      }
    }
    &--answer {
      text-align: start;
      align-content: center;
      padding-left: 5px;
      padding-right: 10px;
      display: grid;
    }

    //disable hover effect on mobile using @media
    @media (hover: hover) {
      &:not(.options__opt--no-hover):hover {
        background: var(--v-primary-base);
        color: white;
        .v-icon {
          color: white !important;
        }
      }
    }

    &--not-selected {
      &__oddOneOut {
        color: black;
        background: rgb(245, 194, 80);
      }
    }

    &--selected {
      color: white;
      cursor: default;
      background-image: linear-gradient(
        to right,
        var(--v-primary-base),
        #03c983
      );
      &__oddOneOut {
        background-image: none;
        background: var(--v-primary-base);
        color: white;
      }
      .v-icon {
        color: white !important;
      }
    }
    &--correctAnswer {
      background: green;
      color: white;
      .v-icon {
        color: white !important;
      }
    }
    &--wrongAnswer {
      background-image: linear-gradient(
        to right,
        var(--v-primary-base),
        #c90303
      );
      &__oddOneOut {
        background-image: none;
        background: red;
        color: white;
      }
    }
    &--no-hover {
      cursor: default;
    }
  }
}
</style>
