<template>
  <div>
    <QuizShell
      i18n_instructionsKey="quizzesInstruction"
      :currentQuestionNumber="currentQuestionIndex + 1"
      :totalNumberOfQuestions="quizQuestions.length"
      :correctCount="correctCount"
      :wrongCount="wrongCount"
      :displaySubmit="false"
      :displayNextQuestionBtn="displayNextButton"
      :displayPreviousQuestionBtn="displayPreviousButton"
      @onNextQuestion="goToNextQuestion"
      @onPreviousQuestion="goToPreviousQuestion"
      :soundEffects="false"
      :customHeader="customHeader"
      :backgroundMusic="quizData.backgroundMusic"
      :showAudioSetting="false"
    >
      <template v-slot:question class="questionBox">
        <div v-if="!quizFinished">
          <div>
            <div
              class="text-center font-weight-black text-h6 text-sm-h5 pa-1 questionBox__question"
            >
              <p v-html="currentQuestionText"></p>
            </div>
          </div>
        </div>
        <div class="reportCard" v-if="quizFinished">
          <ReportCard
            class="mb-5"
            :reportData="{
              level: quizTitle,
              totalNumberOfQuestions: quizQuestions.length,
              correctCount: correctCount
            }"
          />
          <v-btn color="primary" @click="playAgain()">{{
            $t('Play Again')
          }}</v-btn>
        </div>
        <AreYouSure
          :visible="areYouSureDialog"
          :cardTitle="$t('are_you_sure_quiz_title')"
          :cardText="$t('are_you_sure_quiz_content')"
          @areYouSure="areYouSure"
          @close="areYouSureDialog = false"
        ></AreYouSure>
      </template>

      <template #answer>
        <div v-if="!hideAnswerBox">
          <SingleSelectOptions
            v-if="currentQuestion"
            :options="currentQuestion?.options"
            :correctAnswerIndex="currentQuestionCorrectAnswerIndex"
            :showAnswer="showAnswer"
            @onShowAnswer="updateScore"
            @onOptionSelect="onOptionSelect"
            optionsDisplayScheme="oddOneOut"
          />
          <div v-if="!quizFinished && showAnswer">
            <div>
              <div class="text-center text-h6 pa-1">
                <p v-html="currentQuestion.reason[$i18n.locale]"></p>
              </div>
            </div>
          </div>
          <v-btn
            v-if="!showAnswer"
            color="primary"
            @click="showAnswer = !showAnswer"
            >{{ $t('Submit Answer') }}</v-btn
          >
          <v-btn
            v-if="showAnswer && !onLastQuestion"
            color="primary"
            @click="goToNextQuestion()"
            >{{ $t('Next Question') }}</v-btn
          >
          <v-btn
            v-if="showAnswer && onLastQuestion"
            color="primary"
            @click="viewScore()"
            >{{ $t('View Score') }}</v-btn
          >
        </div>
      </template>
    </QuizShell>
  </div>
</template>
<script>
import QuizShell from '../components/common/QuizShell.vue'
import SingleSelectOptions from '../components/common/SingleSelectOptions.vue'
import ReportCard from '../components/common/QuizReportCard.vue'
import AreYouSure from '../components/AreYouSure.vue'
import Utils from '../util/Utils'
export default {
  name: 'Quiz',
  components: {
    QuizShell,
    SingleSelectOptions,
    ReportCard,
    AreYouSure
  },
  mounted() {
    this.reset()
  },
  data() {
    return {
      currentQuestionIndex: -1,
      correctCount: 0,
      wrongCount: 0,
      displayNextButton: false,
      displayPreviousButton: false,
      showAnswer: false,
      showNextQuestionBtn: false,
      quizFinished: false,
      selectedOptionIndex: -1,
      areYouSureDialog: false
    }
  },
  computed: {
    quizId() {
      return 'oddOneOut'
    },
    quizData() {
      return this.$store.state.practicePlayPages.find(
        (p) => p.id === 'oddOneOut'
      )
    },
    quizTitle() {
      return this.quizData.name[this.$i18n.locale]
    },
    customHeader() {
      return undefined
    },
    hideAnswerBox() {
      if (this.customHeader) {
        return true
      } else {
        return this.quizFinished
      }
    },
    quizQuestions() {
      return this.$store.state.oddOneOut
    },
    currentQuestion() {
      return (
        this.currentQuestionIndex != -1 &&
        this.quizQuestions[this.currentQuestionIndex]
      )
    },
    currentQuestionOptions() {
      return this.currentQuestion?.options
    },
    currentQuestionOptionsWithMultipleAnswers() {
      let mutlipleAnswersOptions = []
      if (this.currentQuestion?.question.indexOf('_A_') >= 0) {
        //transform from 'A = ભાવ ; B = માંદો' to {'_A_':ભાવ, '_B_':'માંદો'}
        this.currentQuestionOptions.forEach((o) => {
          let tempAnswers = {}
          let answerOptions = o.split(' ; ')
          answerOptions.forEach((a) => {
            const temp = a.split(' = ')
            tempAnswers[`_${temp[0]}_`] = temp[1]
          })
          mutlipleAnswersOptions.push(tempAnswers)
        })
      }
      //{'_A_':ભાવ, '_B_':'માંદો'}
      return mutlipleAnswersOptions
    },
    currentQuestionText() {
      if (this.currentQuestionIndex < 0) {
        return ''
      }
      //If question consists of multiple answers with _A_, _B_ etc..
      return this.$t('question_oddOneOut')
    },
    currentQuestionImage() {
      if (this.currentQuestion?.questionImage) {
        return Utils.imgUrlByPath(
          `quizzes/${this.quizId}/${this.currentQuestion?.questionImage}`
        )
      } else return undefined
    },
    currentQuestionCorrectAnswerIndex() {
      return this.currentQuestion?.options?.findIndex(
        (o) => o === this.currentQuestion.answer[0]
      )
    },
    onLastQuestion() {
      return this.currentQuestionIndex === this.quizQuestions.length - 1
    },
    maxImageHeight() {
      return Math.min(window.innerWidth, window.innerHeight * 0.5)
    }
  },
  methods: {
    reset() {
      this.currentQuestionIndex = 0
      this.correctCount = 0
      this.wrongCount = 0
      this.displayNextButton = false
      this.displayPreviousButton = false
      this.showAnswer = false
      this.showNextQuestionBtn = false
      this.quizFinished = false
      this.selectedOptionIndex = -1
    },
    areYouSure(yesNo) {
      if (yesNo) {
        this.currentQuestionIndex++
      }
      this.areYouSureDialog = false
    },
    goToNextQuestion() {
      this.currentQuestionIndex++
      this.showAnswer = false
      this.showNextQuestionBtn = false
      this.selectedOptionIndex = -1
    },
    goToPreviousQuestion() {
      this.currentQuestionIndex--
    },
    onOptionSelect(selectedOptionIndex) {
      this.selectedOptionIndex = selectedOptionIndex
    },
    updateScore(isAnswerCorrect) {
      isAnswerCorrect ? this.correctCount++ : this.wrongCount++
      if (this.currentQuestionIndex < this.quizQuestions.length - 1) {
        this.showNextQuestionBtn = true
      }
      if (this.selectedOptionIndex === -1)
        this.selectedOptionIndex = this.currentQuestionCorrectAnswerIndex
    },
    viewScore() {
      this.quizFinished = true
    },
    playAgain() {
      this.reset()
    },
    replaceWithSpan(text, searchFor, replaceWith) {
      return text.replaceAll(searchFor, `<span>${replaceWith}</span>`)
    }
  }
}
</script>
<style lang="scss" scoped>
.questionBox {
  &__question {
    word-break: break-word;

    ::v-deep span {
      border: 1px solid var(--v-primary-base);
      min-width: 3rem;
      display: inline-block;
      color: white;
      background-color: var(--v-primary-base);
      padding: 1px 5px 0 5px;
    }
  }

  &--videoIcon {
    color: var(--v-primary-lighten3);
  }
}
.reportCard {
  text-align: center;
}
</style>
